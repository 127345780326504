<template>
  <div class="login-container">
    <el-form :model="ruleForm2" ref="ruleForm1" :rules="rules2" status-icon label-position="left" label-width="0px"
      class="demo-ruleForm login-page">
      <h3 class="title">系统登录</h3>
      <el-form-item prop="username">
        <el-input type="text" v-model="ruleForm2.username" auto-complete="off" placeholder="用户名" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="ruleForm2.password" auto-complete="off" placeholder="密码" />
      </el-form-item>
      <!--  <el-checkbox 
                v-model="checked"
                class="rememberme"
            >记住密码</el-checkbox> -->
      <el-form-item style="width: 100%">
        <el-button type="primary" style="width: 100%" @click="handleSubmit" :loading="logining" native-type="submit">登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { getCurrentInstance, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import {useRoute} from "vue-router"
import { ElMessage } from 'element-plus'
let { proxy } = getCurrentInstance();

const store = useStore();
const logining = ref(false);
let ruleForm2 = reactive({
  username: "",
  password: "",
});
//监听路由变化
const route = useRoute()
let state = reactive({
  otherQuery: {},
  redirect: undefined
})

let getOhterQuery = (query) =>{
    return Object.keys(query).reduce((acc,cur)=>{
        if(cur !== 'redirect'){
            acc[cur] = query[cur]
        }
        return acc
    },{})
}
watch(route,(route)=>{
    const query = route.query
    if(query){
        state.redirect = query.redirect
        state.otherQuery = getOhterQuery(query)
    }
    },
    {immediate:true}
)

const rules2 = reactive({
  username: [{ required: true, message: "请输入用户名!", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码!", trigger: "blur" }],
});

// const checked = false

const handleSubmit = () => {

  // eslint-disable-next-line no-unused-vars
  let ruleForm1 = "";
  proxy.$refs["ruleForm1"].validate((valid) => {
    if (valid) {
      logining.value = true;
      store.dispatch('user/login', ruleForm2)
      // eslint-disable-next-line no-unused-vars
      .then((res)=>{
          ElMessage({ message: '登录成功', type: 'success' })
           proxy.$router.push({ path: state.redirect || '/', query: state.otherQuery })
        
      // eslint-disable-next-line no-unused-vars
      }).catch((err)=>{
          logining.value = false;
          console.log(err)
          ElMessage({ message: err.message, type: 'error' })
      })
    } else {
      console.log("error submit!");
      return false;
    }
  });
};
</script>

<style scoped>
.title {
  text-align: center;
}
.login-container {
  width: 100%;
  height: 100%;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
